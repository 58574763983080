// @use "./assets/scss/abstracts/variables" as *;
// @use "./assets/scss/abstracts/card" as *;
::-webkit-scrollbar {
  width: 10px;
  height: 10px !important;
}

::-webkit-scrollbar-thumb {
  background: #dfd8d8;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
}
