.card,
.card-light {
  border-radius: 0px !important;
  background-color: $white-color;
  // margin-bottom: 30px;
  box-shadow: 0 0 0 0;
  border: 1px solid #e6eaef;
  .card-header {
    padding: 0.5rem;
    background-color: $transparent-bg;
    border-bottom: 1px solid #ebecec !important;
    &:first-child {
      border-radius: 0px;
    }
    .card-head-row {
      display: flex;
      align-items: center;
      .card-tools {
        margin-left: auto;
        float: right;
        padding-left: 15px;
      }
    }
  }
  .full-height {
    height: calc(100% - 30px);
  }
  .card_solid_separator {
    border-top: 1px solid #ebecec;
    margin: 15px 0;
  }
  .card_dashed_separator {
    border-top: 1px dashed #ebecec;
    margin: 5px 0;
  }
  .separator-dot {
    border-top: 1px dotted #ebecec;
    margin: 15px 0;
  }
  .full-width-separator {
    margin: 15px -20px 15px;
  }
  .b-b1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .card-body {
    padding: 0.8rem;
  }
  .card-category {
    margin-top: 8px;
    font-size: 14px;
    color: #8d9498;
    margin-bottom: 0px;
    word-break: normal;
  }
  label {
    font-size: 14px;
    font-weight: $font-weight-normal;
    color: #8d9498;
    margin-bottom: 0px;
  }
  .card-footer {
    background-color: $transparent-bg;
    line-height: 30px;
    border-top: 1px solid #ebecec !important;
    font-size: 13px;
  }
}
// Modals
