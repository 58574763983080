.error_page {
    height: 100vh;
    position: relative;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #6A707E;
    background: #F0F1F3;

    h1 {
        font-size: 120px;
        color: #6A707E;
        letter-spacing: .10em;
        font-weight: 600;
        animation-delay: .5s;
    }

    .errorMsg {
        font-size: 27px;
        text-align: center;
        line-height: 50px;
        animation-delay: 1.5s;
        letter-spacing: 2px;
        display: flex;
        flex-direction: column;

        .error_Header {
            font-weight: 600;
            font-size: 30px;
        }

        .error_Response {
            font-weight: 500;
            font-size: 24px;
        }
    }

    .backtoHome {
        border-radius: 50px;
        padding: 13px 25px;
        animation-delay: 2.5s;
    }

    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}