// Reset and normalize styles

// Root elements
html,
body {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 100vh;
  // overflow-y: auto;
  overflow-x: hidden;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior-y: contain;
}
