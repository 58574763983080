// .modal-title {
//   color: #6a707e;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
//   letter-spacing: 0.16px;
// }

.modal {
  .bg-default .modal-title,
  .bg-primary .modal-title,
  .bg-secondary .modal-title,
  .bg-info .modal-title,
  .bg-success .modal-title,
  .bg-warning .modal-title,
  .bg-danger .modal-title {
    color: $white-color !important;
  }
}

.modal-content {
  border-radius: 0.4rem !important;
  border: 0 !important;
}

.contentMsg {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: $font-weight-semibold;
}
.modal-footer {
  border: none;
}
