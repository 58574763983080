// Typography rules
// Root elements
// :root {
//   --font-family-sans-serif: "Open Sans", sans-serif;
//   --font-family: "IBM Plex Sans", sans-serif;
// }

html,
body {
  //   font-family: var(--font-family) !important;
  font-family: $font-type1;
  background: $body-Bg-Color;
  font-size: $font-size !important;
  overflow-y: auto;
  font-weight: initial;
  line-height: 20px;
  color: #333333;
  // #1d7cbf
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $primary-color;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $primary2-color;
    text-decoration: none;
  }
  &[href^="mailto:"] {
    text-decoration: none;
    opacity: 0.8;

    &:hover {
      text-decoration: underline;
    }
  }
}

p {
  font-size: 14px;
  line-height: 1.82;
  margin-bottom: 1rem;
  //   word-break: break-word;
}

small,
.small {
  font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-type2;
  font-weight: $font-weight-bold;
  line-height: 1;
}

.fw-light {
  font-weight: $font-weight-light !important;
}

.fw-normal {
  font-weight: $font-weight-normal !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

.fw-bold {
  font-weight: $font-weight-bold !important;
}

.fw-extrabold {
  font-weight: $font-weight-extrabold !important;
}

.header-title {
  margin: 0;
  color: $page-title-color;
  font-size: 13px;
  font-weight: $font-weight-semibold;
  line-height: 1.6;
}
