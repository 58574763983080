.cursor-pointer {
  cursor: pointer;
}

.icon-color {
  color: $icon-color;
}

.icon {
  color: $icon-color;
  font-size: 12px;
}

.invalid-feedback {
  margin-top: 0.35rem !important;
  color: #dc3545;
}

.customError {
  color: #dc3545;
  font-size: 0.875em;
  margin-top: 1rem;
}
// This class is used in aligning the button to the right side of the Tabs
.customPosition {
  position: absolute;
  top: 7px;
  right: 28px;
}

.customToggle {
  .form-check-input {
    cursor: pointer !important;
  }
}
