// Buttons

.btn {
  // outline: none !important;
  // &:hover {
  //   transition: all 0.3s;
  // }
  // &:active,
  // &:active:hover,
  // &:active:focus,
  // &.active {
  //   border: none;
  // }
  // &:active:focus {
  //   border: none;
  // }
  &.show {
    border-color: #f8f8f8 !important;
  }
  &:disabled {
    border: none;
    cursor: not-allowed !important;
  }
  // &:focus {
  //   border: none;
  // }
}

.btn-sm {
  font-size: $btn-fontSize;
  font-weight: $font-weight-semibold;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 5px;
  width: 7px 15px;
}

.btn-xs {
  font-size: 10px;
  padding: 5px 9px;
}

.btn {
  &.disabled:hover,
  &:hover:disabled {
    opacity: 0.65;
  }
  &:active {
    border-color: transparent;
    border: 0;
  }
}

.btn-icon {
  font-size: 0.9375rem;
  height: 2.5125rem;
  line-height: normal;
  min-width: 2.5125rem;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 2.5125rem;
  &.btn-lg {
    height: 2.75rem;
    min-width: 2.75rem;
    width: 2.75rem;
  }
  &.btn-sm {
    height: 2rem;
    min-width: 2rem;
    width: 2rem;
  }
  &.btn-xs {
    height: 1.6875rem;
    min-width: 1.6875rem;
    width: 1.6875rem;
  }
}
