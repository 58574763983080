// Reusable Classes

// Images
.center-image {
  display: block;
  margin: 0 auto;
}
.c-pointer {
  cursor: pointer;
}
.icon-color {
  color: #4f4f4f;
}
.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}
