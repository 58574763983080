// Dropdown

// .dropdown-item {
//   height: 30px;
//   font-size: 12px;
//   width: 100%;
//   line-height: normal;
//   padding: 0 10px;
//   font-weight: 400;
// }
.dropdown-menu {
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.15) !important;
  padding-bottom: 8px;
  margin-top: 3px;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  text-align: left;
  list-style: none;
}

.dropdown-item {
  height: 30px;
  display: block;
  width: 100%;
  padding: 0 10px;
  clear: both;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-toggle::after {
  display: none;
}
