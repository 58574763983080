.nav-link {
  background: none !important;
  color: #666 !important;
  border: 0px transparent !important;
}

.nav-link:hover {
  border: 0px transparent !important;
}
// .nav-link:focus {
//   border-bottom: 1px solid #302f2f !important;
// }

.nav-link.active {
  border-bottom: 2px solid #1673e8 !important;
}
