//# color.

// $body-Bg-Color: #f0f1f3;
$body-Bg-Color: #f9fbfd;
$body-text-color: #575962;
$white-color: #ffffff;
$red-color: #8b0000;
$grey-color: #f1f1f1;
$grey2-color: #f9fbfd;
$black-color: #191919;
$transparent-bg: transparent;
$dark-color: #1a2035;
$dark2-color: #1f283e;
$primary-color: #1572e8;
$primary2-color: #1269db;
$secondary-color: #6861ce;
$secondary2-color: #5c55bf;
$info-color: #48abf7;
$info2-color: #3697e1;
$success-color: #31ce36;
$success2-color: #2bb930;
$warning-color: #ffad46;
$warning2-color: #ff9e27;
$danger-color: #f25961;
$danger2-color: #ea4d56;
$icon-color: #898585;

$page-title-color: #333333;
$label-color: #495057;

// Font Type & Weights
$font-type1: "RobotoRegular", sans-serif;
$font-type2: "IBM Plex Sans", sans-serif;
$font-type3: "Lato", sans-serif;
$font-type4: "Montserrat", sans-serif;
$default-font-size: 0.875rem; // 14px as base font size
$font-size: 13px;
$btn-fontSize: 12px;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-extrabold: 700;

// Positions
$positions: top, right, bottom, left;
$zindex: 1111;
