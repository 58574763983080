// Forms and form input styling
.form-control {
  font-size: 14px;
  border-color: #ebedf2;
  padding: 0.6rem 1rem;
  height: inherit !important;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    opacity: 0.6;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 0.6;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    opacity: 0.6;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    opacity: 0.6;
  }
  &:focus {
    border-color: #3e93ff;
  }
}

.form-label {
  line-height: 1.8;
  color: $label-color !important;
  font-weight: $font-weight-semibold;
  margin-bottom: 0.3rem;
  margin-left: 0.2rem;
  font-size: 12px;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}
.invalid-feedback {
  margin-top: 0.35rem !important;
}
.form-check-label {
  margin-left: 2px !important;
  margin-top: 3px !important;
}
