.avatar {
  position: relative;
  display: inline-block;
  mix-blend-mode: multiply;
  -o-object-fit: cover;
  object-fit: cover;
  .border {
    border-width: 3px !important;
  }
  .rounded {
    border-radius: 6px !important;
  }
}

.avatar-xs {
  width: 1.65rem;
  height: 1.65rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem;
}

.avatar-xl {
  width: 5rem;
  height: 5rem;
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-online::before,
.avatar-offline::before,
.avatar-away::before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: "";
  border: 2px solid #fff;
}

.avatar-online::before {
  background-color: $success-color;
}

.avatar-offline::before {
  background-color: #97a2b1;
}

.avatar-away::before {
  background-color: $warning-color;
}

.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: 15px auto;

  .avatar-edit {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    margin-top: 50%;

    input {
      display: none;
    }

    label {
      opacity: 1;
      background: #f1f1f1;
      border-color: #d6d6d6;
      display: inline-block;
      width: 34px;
      height: 34px;
      margin-bottom: 0;
      border-radius: 100%;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      cursor: pointer;
      font-weight: normal;
      transition: all 0.2s ease-in-out;
    }

    label span {
      color: #757575;
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      text-align: center;
      margin: auto;
    }
  }

  .avatar-preview {
    width: 122px;
    height: 122px;
    object-fit: contain;
    position: relative;
    border-radius: 100%;
    mix-blend-mode: multiply;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
}
